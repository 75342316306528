import React from 'react';
import styled from 'styled-components';

const PatreonList = () => {
  return (
    <div className="patreon-container">
      <div className="patreon-background">
        <img 
          src="https://i.ibb.co/mCkrmcf/PATREON-1.png" 
          alt="Patreon Banner" 
          className="patreon-image"
        />
              <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
      </div>
      <h2 className="patreon-heading">PATREON PODPOROVATELÉ</h2>
      <ul className="patreon-list">
        <li>Jakub Merhaut - Tier 3</li>
        <li>Boxik- Tier 2</li>
      </ul>
    </div>
  );
};

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }
`;

export default PatreonList;
