import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ImgSlider from './ImgSlider';
import Viewers from './Viewers';
import NejoblibenejsiSLIDER from './Home Page/NejoblibenejsiSLIDER';
import DobrodruznyPOSTERSLIDER from './Home Page/DobrodruznyPOSTERSLIDER';
import RodinnySLIDER from './Home Page/RodinnySLIDER';
import DoporucujemeSLIDER from './Home Page/DoporucujemeSLIDER';
import AkcniSLIDER from './Home Page/AkcniSLIDER';
import NinjagoKOLEKCE from './Home Page/NinjagoKOLEKCE';
import DCPOSTERSLIDER from './Home Page/DCPOSTERSLIDER';
import FantasySLIDER from './Home Page/FantasySLIDER';
import KomedieSLIDER from './Home Page/KomedieSLIDER';

function Home(props) {
  const [isModalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    setModalOpen(true);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <Helmet>
        <title>NCE+ | Streamovací platforma</title>
      </Helmet>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>×</CloseButton>
            <Text>Pomoz nám získat 1000 členů na Discordu, zabere to pár sekund</Text>
            <VideoWrapper>
              <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/Bz6zhOQBcBs?autoplay=1" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </VideoWrapper>
            <a href="https://discord.com/invite/dwRN6v9vz8" target="_blank" rel="noopener noreferrer">
              <DiscordButton>
                <img src="https://i.ibb.co/ZB161vk/png-transparent-discord-computer-servers-teamspeak-discord-icon-video-game-smiley-online-chat-thumbn.png" alt="Discord Logo" />
                PŘIPOJIT SE
              </DiscordButton>
            </a>
          </ModalContent>
        </Modal>
      )}
      <ImgSlider loading="eager" />
      <Viewers loading="eager" />
      <NejoblibenejsiSLIDER />
      <DobrodruznyPOSTERSLIDER />
      <RodinnySLIDER />
      <AkcniSLIDER />
      <KomedieSLIDER />
      <DCPOSTERSLIDER />
      <FantasySLIDER />
      <DoporucujemeSLIDER />
      <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
    </Container>
  );
}

const Container = styled.main`
  position: relative;
  overflow-x: hidden;
  display: block;
  top: 72px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0.95;
`;

const ModalContent = styled.div`
  background-color: rgba(33, 33, 33, 0.9);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  max-width: 600px;
  width: 90%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
`;

const Text = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: white;
`;

const VideoWrapper = styled.div`
  margin-bottom: 20px;
`;

const DiscordButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5865F2;
  color: white;
  border: none;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  width: 100%;
  box-sizing: border-box;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;

export default Home;
