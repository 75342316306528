import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import React from 'react';
import OdNCEText from './OdNCEText';
import OdNCEPLUS from './OdNCE+';
import NCESpoty from './NCESpoty';
import Bottom from '../Bottom';
import DOPSTRNAKY from './DOPSTRANKY';
import NCEDabingy from './NCEDabingy';

function OdNce(props) {
  return (
    <Container>
      <Background>
        <img className="scdown" src="https://i.ibb.co/kcLNjZh/BACK.jpg" loading="eager" />
      </Background>
      <Helmet>
        <title>Od NCE+ | NCE+</title>
        <meta name="description" content="Dabingy, sociální sítě a spoty od NCE+" />
      </Helmet>
      <OdNCEText />
      <OdNCEPLUS />
      <DOPSTRNAKY />
      <NCEDabingy />
      <NCESpoty />
    </Container>
  );
}

const Container = styled.main`
overflow: hidden;
  position: relative;
  padding: 0 calc(3.5vw + 5px);
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom 0;
    right: 0;
    z-index -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
    }
`;

export default OdNce;
